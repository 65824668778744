import * as React from 'react'
import { graphql, Link } from 'gatsby'
import { Container } from '../components/Container'
import { useMap } from '../utils/mapProvider'
import { FaSearchPlus } from "react-icons/fa"
import { PrismicRichText,PrismicLink } from '@prismicio/react'
import { StaticImage } from 'gatsby-plugin-image'
import * as s from '../templates/WhereAmI.module.scss';
import { Icon } from './where_am_i/Icon'

export const WhereAmIDetails = ({data, labels}) => {
  const { 
    status, 
    tables, 
    isLoading 
  } = useMap();
  console.log('labels', labels)
  const [localData, setLocalData] = React.useState({
    treaties: [],
    territories: [],
    schools: [],
    communities: [],
    cities: [],
    businesses: [],
    chats: []
  });
  React.useEffect(() => {
    if(tables) {
      setLocalData((prev) => ({
        ...prev,
        treaties: tables.find(table => table.label === 'treaties')?.data || [],
        territories: tables.find(table => table.label === 'territories')?.data || [],
        schools: tables.find(table => table.label === 'schools')?.data || [],
        communities: tables.find(table => table.label === 'communities')?.data || [],
        cities: tables.find(table => table.label === 'cities')?.data || [],
        businesses: tables.find(table => table.label === 'businesses')?.data || [],
        chats: tables.find(table => table.label === 'chats')?.data || []
      }))
    }

  }, [tables])
  return (
    <section style={{paddingTop: 0}}>
      <Container>
        {!isLoading ? 
          (
            <div className={s.FlexWrap} style={{flexWrap: "wrap"}}>
              <div className={s.infoWrap}>
                <div className={s.infoHeader}>
                  
                  <Icon icon={labels.land_icon}/>
                  <PrismicRichText field={data.territories_header?.richText}/>
                </div>
                  <p>{data.territories_info.replace("{{value}}", localData?.territories?.length || 0)}</p>
                  <ul>
                  {localData?.territories?.map(item => (
                    <li key={item.name}><Link to={item.link}>{item.name}</Link></li>
                  ))} 
                  </ul>
                
              </div>
              <div className={s.infoWrap}>
                <div className={s.infoHeader}>
                  <Icon icon={labels.treaties_icon}/>
                  <PrismicRichText field={data.treaties_header?.richText}/>
                </div>
                  <p>{data.treaties_info}</p>
                  <ul>
                  {localData?.treaties?.map(item => (
                    <li key={item.name}><Link to={item.link}>{item.name}</Link></li>
                  ))}
                  </ul>
                
              </div>
              <div className={s.infoWrap}>
                <div className={s.infoHeader}>
                  <Icon icon={labels.schools_icon}/>
                  <PrismicRichText field={data.schools_header?.richText}/>
                </div>
                  <p>{data.schools_info}</p>
                  <ul>
                  {localData?.schools?.map(item => (
                    <li key={item.name}><Link to={item.link}>{item.name}</Link></li>
                  ))} 
                  </ul>
                
              </div>
              <div className={s.infoWrap}>
                <div className={s.infoHeader}>
                  <Icon icon={labels.communities_icon}/>
                  <PrismicRichText field={data.communities_header?.richText}/>
                </div>
                  <p>{data.communities_info}</p>
                  <ul>
                  {localData?.communities?.map(item => (
                    <li key={item.name}><Link to={item.link}>{item.name}</Link></li>
                  ))}
                  </ul>
              </div>
              <div className={s.infoWrap}>
                <div className={s.infoHeader}>
                  <Icon icon={labels.fireside_icon}/>
                  <PrismicRichText field={data.chats_header?.richText}/>
                </div>
                  <p>{data.chats_info}</p>
                  <ul>
                  {localData?.chats?.map(item => (
                    <li key={item.name}><Link to={item.link}>{item.name}</Link></li>
                  ))}
                  </ul>
              </div>
              <div className={s.infoWrap}>
                <div className={s.infoHeader}>
                  <Icon icon={labels.businesses_icon}/>
                  <PrismicRichText field={data.businesses_header?.richText}/>
                </div>
                  <p>{data.businesses_info}</p>
                  <ul>
                  {localData?.businesses?.map(item => (
                    <li key={item.name}><Link to={item.link}>{item.name}</Link></li>
                  ))}
                  </ul>
              </div>
            </div>
          ) :
          status?.value === 1 ? 
          (
            <></>
          )
          : 
          (
            <div className={s.locationCTA}>
              <PrismicRichText 
                field={data.geolocation_perm?.richText} 
                components={{strong: ({ children }) => <b><FaSearchPlus/>{children}</b>,}}
              />
             
            </div>
          )
        } 
      </Container>
    </section>
  )
}
export const query = graphql`
  fragment WhereAmIDetails on PrismicWhereAmI {
    data {
      geolocation_perm {
        richText
      }
      territories_header {
        richText
        text
      }
      territories_info
      treaties_header {
        richText
        text
      }
      treaties_info
      schools_header {
        richText
      }
      schools_info
      communities_header {
        richText
      }
      communities_info
      businesses_header {
        richText
      }
      businesses_info
      chats_header {
        richText
      }
      chats_info
  }
}
    
`
