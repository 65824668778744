import React, { useState, useEffect, useContext, createContext } from 'react'
import { getDistance } from 'geolib';
import { LoadScript } from '@react-google-maps/api';
import { loading, loadingSpinner} from '../slices/Map.module.scss'

import slugify from './slugify';
import { keyMergeMap } from './airtableKeyMerge';


const lib = ["places"];
const idG = ["614f2b924c037cf7"];
const key = 'AIzaSyAkMp-DRCWbGCpLz9uqAUJq6-1sVPJBcQ0'; 
const defaultLocation = { lat: 60.219386, lng: -101.645961 };

const tableOptions = [
  {
    label: 'treaties',
    id: 0,
    table: 'tblNbzH8fyCHl9Uak',
    type: 'polygon'
  },
  {
    label: 'communities',
    id: 2,
    table: 'tblA1YdPobD3JN7iG',
    type: 'marker'
  },
  {
    label: 'territories',
    id: 3,
    table: 'tblD4d5rF383Bcbil',
    type: 'polygon'
  },
  {
    label: 'schools',
    id: 4,
    table: 'tblDUmxXXDiJcKcvv',
    type: 'marker'
  },
  {
    label: 'schools',
    id: 4,
    table: 'tblWfl46Mffq0IU46',
    type: 'marker'
  },
  {
    label: 'chats',
    id: 5,
    table: 'tblqKWImuUjMmUNU8',
    type: 'marker'
  },
  {
    label: 'businesses',
    id: 6,
    table: 'tblLvLuIFUJSVd3QS',
    type: 'marker'
  },
]
const colors = [
  "#020202",
  "#AF4222",
  "#E6CB5C",
  "#372C2F",
  "#DB6642",
  "#FFE45C",
  "#468A7F",
  "#F8E4D2"
]
const statusTypes = {
  loading: {value: 1, label: 'Locating...'},
  denied: {value: 0, label: 'Geolocation is not supported by your browser'},
  failed: {value: 0, label: 'Unable to retrieve your location'},
  success: {value: 2, label:  'Success!'}
}
  
const mapContext = createContext()

export const useMap = () => useContext(mapContext)

// id 0 is homepage
// id 1 is where am I
export function MapProvider({ children,data,airtableData, id,lang}) {
  const map = useMapProvider(data,id,airtableData, lang)
  return (
    <mapContext.Provider value={map}>
      <LoadScript
        googleMapsApiKey={key} 
        libraries={lib} 
        mapIds={idG} 
        loadingElement={Loading}
        language={lang}
      >
        {children}
      </LoadScript>
    </mapContext.Provider>
  )
}
export const Loading = (
  <div className={loading}>
    <p>Map Loading</p>
    <div className={loadingSpinner}>
    </div>
  </div>
);



function useMapProvider(data,id,airtableData, lang) {
  const [coordinates, setCoordinates] = useState(null)


  const [tables, setTables] = useState(null)
  const [treaties, setTreaties] = useState(null)
  const [territories, setTerritories] = useState(null)
  const [cities, setCities] = useState(null)
  const [communities, setCommunities] = useState(null)
  const [schools, setSchools] = useState(null)
  const [chats, setChats] = useState(null)
  const [businesses, setBusinesses] = useState(null)

  const [status, setStatus] = useState(statusTypes['loading'])
  const [isLoading, setIsloading] = useState(true);

  useEffect(async () => {
    setIsloading(true);
    const tablesArray = reduceArrayByTable(airtableData, lang);
    const c = await fetchDataFromSingle(data.city_kml.url);
    const citiesData = c.features.map((city) => ({
      name: city.properties.Name,
      coordinates: {lat: city.geometry.coordinates[1], lng: city.geometry.coordinates[0]},
      descriptionHTML: city.properties.description,
    }));
    tablesArray.push({id: 1,  type: 'marker', label: 'cities', data: citiesData});
    tablesArray.sort((a,b) => a.id - b.id);
    
    if(id === 1) {
      getLocal().then((coord) => {
        setCoordinates(coord);
        const localTables = tablesArray.map(table => {
          if (table.type === 'polygon') {
            return {
              ...table,
              data: getNearbyPolygons(coord, table.data)
            }
          } else if (table.type === 'marker') {
            return {
              ...table,
              data: getNearbyPoints(coord, table.data, 100000)
            }
          }
        });
        setTables(localTables);
        
        
      }).catch((err) => {
        setStatus(statusTypes[err])
      });
    } else if(id === 0) {
      setTables(tablesArray);
      
      setBusinesses(tablesArray[6].data)
      setChats(tablesArray[5].data)
      setSchools(tablesArray[4].data)
      setCommunities(tablesArray[2].data)
      setCities(tablesArray[1].data)
      setTerritories(tablesArray[3].data)
      setTreaties(tablesArray[0].data)
    }
    
    else {
      setTables(tablesArray);
    }
    
    setIsloading(false);
  }, []);

  return {
    coordinates,
    isLoading,
    defaultLocation,
    status,
    colors,
    tables,
    pathify,
    isInsidePolygon,
    treaties,
    territories,
    cities,
    communities,
    schools,
    chats,
    businesses
  }
}

function reduceArrayByTable(data, lang){
  const reducedData = data.reduce((acc, currentItem, idx) => {
    const tableOption = tableOptions.find(opt => opt.table === currentItem.table);
    const currentTable = acc.find(item => item.id === tableOption.id);
    const currentData = keyMergeMap(currentItem.table,currentItem.data, lang);
    if (currentTable) {
      currentTable.data.push(currentData);
    } else {
      acc.push({ id: tableOption.id, type: tableOption.type, label: tableOption.label, data: [currentData]});
    }

    return acc;
  }, []);
  return reducedData;
}

function getLocal() {
  return new Promise((resolve,reject) => {
    const lat = Number(localStorage.getItem('user-lat'))
    const lng = Number(localStorage.getItem('user-lng'))

    if( lat && lng) {
      resolve({lat: lat, lng: lng})
    } else {
      if(!navigator.geolocation){
        reject('failed')
      } else {
        navigator.geolocation.getCurrentPosition((pos) => {
          const coordObject = {lat: pos.coords.latitude, lng: pos.coords.longitude}
          localStorage.setItem('user-lat', coordObject.lat)
          localStorage.setItem('user-lng', coordObject.lng)
          resolve(coordObject)
        }, () => reject('denied'))
      }
    }
    
  })
}


function getNearbyPoints(coord, list, max_distance){
  return list.filter(x => 
    getDistance(
      {latitude: x.coordinates.lat, longitude: x.coordinates.lng },
      {latitude: coord?.lat, longitude: coord?.lng}
    ) < max_distance
  );
}
function getNearbyPolygons(coord, list){
  return list.filter(x => 
    isInsidePolygon(coord, x.coordinates )  
  );
}
const fetchDataFromSingle = async (url) => {
  try {
    const res = await fetch(url);
    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
    const resJson = await res.json();
    return resJson;
  } catch (err) {
    console.error(err);
  }
};



/* Helper Functions*/
function pathify(list){
  const cur = [];
  list?.every(item => 
    cur.push({lat: item.Lat, lng: item.Long})
  )
  return cur;
}

function isInsidePolygon(point, vs){
  if(!vs) return false;
  var x = point.lat, y = point.lng;

  var inside = false;
  for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
      var xi = vs[i].lat, yi = vs[i].lng;
      var xj = vs[j].lat, yj = vs[j].lng;
      
      var intersect = ((yi > y) != (yj > y))
          && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
      if (intersect) inside = !inside;
  }
  return inside;
}
