// extracted by mini-css-extract-plugin
export var BtnOrange = "Contact-module--BtnOrange--28001";
export var Container = "Contact-module--Container--7cd3c";
export var FlexWrap = "Contact-module--FlexWrap--1f491";
export var H1 = "Contact-module--H1--7b5c8";
export var H2 = "Contact-module--H2--91637";
export var H3 = "Contact-module--H3--ba7f4";
export var H4 = "Contact-module--H4--9b0e6";
export var H5 = "Contact-module--H5--183d7";
export var H6 = "Contact-module--H6--c52af";
export var blockImg = "Contact-module--block-img--85069";
export var contentPage = "Contact-module--contentPage--86429";
export var headerWrap = "Contact-module--headerWrap--fb7b6";
export var sliderTray = "Contact-module--sliderTray--b3194";