// extracted by mini-css-extract-plugin
export var BtnOrange = "WhyAcknowledge-module--BtnOrange--619ad";
export var Container = "WhyAcknowledge-module--Container--2643a";
export var FlexWrap = "WhyAcknowledge-module--FlexWrap--3cf8d";
export var H1 = "WhyAcknowledge-module--H1--db04e";
export var H2 = "WhyAcknowledge-module--H2--45e10";
export var H3 = "WhyAcknowledge-module--H3--4b658";
export var H4 = "WhyAcknowledge-module--H4--25322";
export var H5 = "WhyAcknowledge-module--H5--02fbf";
export var H6 = "WhyAcknowledge-module--H6--58038";
export var blockImg = "WhyAcknowledge-module--block-img--5dbd6";
export var contentPage = "WhyAcknowledge-module--contentPage--67e16";
export var copyWrap = "WhyAcknowledge-module--copyWrap--d7be8";
export var ctaWrap = "WhyAcknowledge-module--ctaWrap--2384d";
export var sliderTray = "WhyAcknowledge-module--sliderTray--97964";