// extracted by mini-css-extract-plugin
export var BtnOrange = "TopMenu-module--BtnOrange--0fc84";
export var Container = "TopMenu-module--Container--f25e1";
export var FlexCol = "TopMenu-module--FlexCol--a4c8a";
export var FlexWrap = "TopMenu-module--FlexWrap--acbfe";
export var H1 = "TopMenu-module--H1--18f00";
export var H2 = "TopMenu-module--H2--b8845";
export var H3 = "TopMenu-module--H3--bf51e";
export var H4 = "TopMenu-module--H4--d70fd";
export var H5 = "TopMenu-module--H5--78160";
export var H6 = "TopMenu-module--H6--53f99";
export var SubFlexCol = "TopMenu-module--SubFlexCol--93dc6";
export var blockImg = "TopMenu-module--block-img--0a9c2";
export var contentPage = "TopMenu-module--contentPage--ee094";
export var fixedLogo = "TopMenu-module--fixedLogo--8c3ef";
export var fixedMenu = "TopMenu-module--fixedMenu--414db";
export var header = "TopMenu-module--header--73550";
export var hidden = "TopMenu-module--hidden--43cf9";
export var icon = "TopMenu-module--icon--a2cca";
export var iconLink = "TopMenu-module--iconLink--4d157";
export var iconWrap = "TopMenu-module--iconWrap--14762";
export var label = "TopMenu-module--label--6a9d3";
export var labelLink = "TopMenu-module--labelLink--67028";
export var logo = "TopMenu-module--logo--67579";
export var menu = "TopMenu-module--menu--0d8bb";
export var menuLinks = "TopMenu-module--menuLinks--e6fb4";
export var paddedSide = "TopMenu-module--paddedSide--5f5e7";
export var sideActive = "TopMenu-module--sideActive--bb36c";
export var sideMenu = "TopMenu-module--sideMenu--2f981";
export var skip_button = "TopMenu-module--skip_button--1bf34";
export var sliderTray = "TopMenu-module--sliderTray--b097a";
export var visible = "TopMenu-module--visible--a536f";