import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText,PrismicLink } from '@prismicio/react'
import { Container } from "../components/Container"

import * as s from './WhyAcknowledge.module.scss';

export const WhyAcknowledge = ({ slice }) => {
  return (
    <section className={s.WhyAcknowledge}>
      <Container>
        <div className={s.FlexWrap}>
          <div className={s.copyWrap}>
            <PrismicRichText field={slice.primary.rt?.richText}/>
          </div>
          <div className={s.ctaWrap}>
            <PrismicRichText field={slice.primary.box_rt?.richText}/>
          </div>
        </div>
      </Container>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyWhyAcknowledge on PrismicHomepageDataBodyWhyAcknowledge {
    id
    primary {
      rt {
        richText
      }
      box_rt {
        richText
      }
    }
  }
`
