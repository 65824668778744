import * as React from 'react'
import { navigate } from 'gatsby'
import { linkResolver } from '../utils/linkResolver'

import * as s from './LanguageSwitcher.module.scss'

const fullLang = {
  'en-ca': {
    'EN': 'English',
    'FR': 'French',
  },
  'fr-ca': {
    'EN': 'Anglais',
    'FR': 'Français'
  }
}
export const LanguageSwitcher = ({ activeDocMeta }) => {
  const currentLang = activeDocMeta.lang

  function Lang(lang){
    const cLang = fullLang[currentLang][lang] || fullLang['en-ca'][lang]
    return cLang
  }
  const currentLangOption = (
    <option value={currentLang}>{Lang(currentLang.slice(0, 2).toUpperCase())}</option>
  )

  const alternateLangOptions = activeDocMeta.alternateLanguages.map(
    (altLang, index) => (
      <option value={linkResolver(altLang)} key={`alt-lang-${index}`}>
        {Lang(altLang.lang.slice(0, 2).toUpperCase())}
      </option>
    ),
  )

  const handleLangChange = (event) => {
    navigate(event.target.value)
  }

  return (
    <div className={s["banner"]}>
      <div className={s['language_switcher']}>
        <div>
          <label htmlFor="langToggle">{currentLang === 'fr-ca' ? 'Langue: ' : 'Language: '}</label>
          <select id="langToggle" name="langToggle" value={currentLang} onChange={handleLangChange}>
            {currentLangOption}
            {alternateLangOptions}
          </select>
        </div>
      </div>
    </div>
  )
}
