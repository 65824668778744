// extracted by mini-css-extract-plugin
export var BtnOrange = "BottomMenu-module--BtnOrange--7f745";
export var Container = "BottomMenu-module--Container--24fa6";
export var FlexWrap = "BottomMenu-module--FlexWrap--ac353";
export var Footer = "BottomMenu-module--Footer--2de26";
export var H1 = "BottomMenu-module--H1--b154f";
export var H2 = "BottomMenu-module--H2--cb2fc";
export var H3 = "BottomMenu-module--H3--51503";
export var H4 = "BottomMenu-module--H4--d2480";
export var H5 = "BottomMenu-module--H5--fd6bb";
export var H6 = "BottomMenu-module--H6--4416f";
export var Row = "BottomMenu-module--Row--c9be0";
export var blockImg = "BottomMenu-module--block-img--6394d";
export var cardRow = "BottomMenu-module--cardRow--9ef56";
export var contentPage = "BottomMenu-module--contentPage--62629";
export var sliderTray = "BottomMenu-module--sliderTray--7a397";