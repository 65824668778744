// extracted by mini-css-extract-plugin
export var BtnOrange = "FAQ-module--BtnOrange--0a2d2";
export var Container = "FAQ-module--Container--3ff2f";
export var FAQ = "FAQ-module--FAQ--4dfbb";
export var FlexWrap = "FAQ-module--FlexWrap--3d33a";
export var H1 = "FAQ-module--H1--6087a";
export var H2 = "FAQ-module--H2--068ae";
export var H3 = "FAQ-module--H3--7734f";
export var H4 = "FAQ-module--H4--f899f";
export var H5 = "FAQ-module--H5--5299d";
export var H6 = "FAQ-module--H6--88dcd";
export var blockImg = "FAQ-module--block-img--7ac3d";
export var contentPage = "FAQ-module--contentPage--83dab";
export var sliderTray = "FAQ-module--sliderTray--d64dc";