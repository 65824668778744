import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText,PrismicLink } from '@prismicio/react'
import { Container } from "../components/Container"
import * as s from './Text.module.scss';

export const Text = ({ slice }) => {
  return (
    <section className={s.Text}>
      <Container>
        <PrismicRichText field={slice.primary.rt?.richText}/>
      </Container>
    </section>
  )
}

export const query = graphql`
  fragment PageDataBodyText on PrismicPageDataBodyText{
    id
    primary {
      rt {
        richText
      }
    }
  }
`
