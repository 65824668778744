import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText,PrismicLink } from '@prismicio/react'
import { Container } from "../components/Container"

import * as s from './FAQ.module.scss';

export const FAQ = ({ slice }) => (
  <section className={s.FAQ}>
    <Container>
      <PrismicRichText field={slice.primary.display_title?.richText}/>
      {slice.items.map((item,index) => (
        <details key={slice.id + index}>
          <summary>{item.question}</summary>
          {item.answer}
        </details>
      ))}
      <PrismicLink  
        href={slice.primary.button_link?.url} 
        className={s.BtnOrange}
      >
        {slice.primary.button_label}
      </PrismicLink>
    </Container>
  </section>
)

export const query = graphql`
  fragment PageDataBodyFaq on PrismicPageDataBodyFaq {
    id
    primary {
      display_title {
        richText
      } 
      button_label 
      button_link {
        url
      }
    }
    items {
      question
      answer
    }
  }
`
