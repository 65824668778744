// extracted by mini-css-extract-plugin
export var BtnOrange = "VideoSlider-module--BtnOrange--76edb";
export var Container = "VideoSlider-module--Container--4cc82";
export var FlexWrap = "VideoSlider-module--FlexWrap--e7735";
export var H1 = "VideoSlider-module--H1--bea1c";
export var H2 = "VideoSlider-module--H2--4a66a";
export var H3 = "VideoSlider-module--H3--492ce";
export var H4 = "VideoSlider-module--H4--4c512";
export var H5 = "VideoSlider-module--H5--b8c4d";
export var H6 = "VideoSlider-module--H6--1a935";
export var blockImg = "VideoSlider-module--block-img--153c2";
export var btnNav = "VideoSlider-module--btnNav--d5704";
export var contentPage = "VideoSlider-module--contentPage--8e391";
export var header = "VideoSlider-module--header--33abd";
export var slide = "VideoSlider-module--slide--82711";
export var sliderTray = "VideoSlider-module--sliderTray--4fa2c";