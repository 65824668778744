import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import {iconBox, layerActive, icon as iconStyle} from '../../slices/Map.module.scss'

const MapLayerButton = ({id, icon, label, isActive, handleLayer}) => {
  return (
    <div className={`${iconBox} ${isActive ? layerActive : ""}`}>
      <button onClick={handleLayer} aria-labelledby={`buttonLabel${id}`}>
        <GatsbyImage
          image={icon?.gatsbyImageData}
          objectFit="contain"
          alt=""
          className={iconStyle}
        />
      </button>
      <span id="buttonLabel6">{label}</span>
    </div>
  )
}

export default MapLayerButton;