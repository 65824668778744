import * as React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { Layout } from '../components/Layout'
import { WhereAmIDetails } from '../components/WhereAmIDetails'
import { WhereAmIMap } from '../components/WhereAmIMap'
import { MapProvider } from '../utils/mapProvider'



const WhereAmITemplate = ({ data }) => {
  if (!data) return null
  const pageContent = data.prismicWhereAmI || {}
  const page = pageContent.data || {}
  const menu = data.prismicMenu || {}
  const filteredNodes = data.allAirtable.nodes.filter(node => node.table !== 'tblLvLuIFUJSVd3QS' || node.data.Approved === true);
  const combinedNodes = [...filteredNodes, ...data.allAirtableChats.nodes, ...data.allAirtableSchools.nodes];
  const { lang, type, url } = pageContent || {}
  const alternateLanguages = pageContent.alternate_languages || []
  const activeDoc = {lang,type,url,alternateLanguages,}

  const mapLayers = page.map_links?.document.data;
  return (
    <MapProvider data={mapLayers} id={1} airtableData={combinedNodes} lang={lang.toLowerCase().slice(0,2)}>
      <Layout menu={menu.data} activeDocMeta={activeDoc}>
        <WhereAmIMap data={page} lang={lang.toLowerCase().slice(0,2)}/>
        <WhereAmIDetails data={page} lang={lang.toLowerCase().slice(0,2)} labels={mapLayers}/> 
      </Layout>
    </MapProvider>  
  )
}

export const query = graphql`
  query whereAmIQuery($id: String, $lang: String) {
    allAirtable(filter: {
      table: {in: ["tblNbzH8fyCHl9Uak", "tblA1YdPobD3JN7iG", "tblD4d5rF383Bcbil", "tblLvLuIFUJSVd3QS"]}
    }
    ) {
      nodes {
        table
        data {
          Name
          Syllabics
          Pronunciation
          Product_Categories
          Color
          ID
          Treaty_Name_EN
          Coordinates
          Latitude
          Longitude
          Slug
          Description_Alt: Field_7
          Description
          Approved
        }
      }
    }
    allAirtableChats {
      nodes {
        table
        data {
          Name
          Web_URL
          Community_Name
          Latitude
          Longitude
          Description: Subtitle

        }
      }
    }
    allAirtableSchools {
      nodes {
        table
        data {
          Name
          Link: URL
          Latitude
          Longitude
          Start_Date
          End_Date
          Operating_Dates
          Location
          Image
        }
      }
    }
    prismicWhereAmI(id: { eq: $id },lang: { eq: $lang }) {
      _previewable
      alternate_languages {
        uid
        type
        lang
      }
      lang
      url
      type
      id
      data {
        map_links {
          document {
            ... on PrismicMapKml {
              data {
                city_label
              city_kml {
                url
              }
              city_icon {
                gatsbyImageData
                alt
              }
              communities_label
              communities_icon {
                gatsbyImageData
                alt
              }
              land_label
              land_icon {
                gatsbyImageData
                alt
              }
              schools_label
              schools_icon {
                gatsbyImageData
                alt
              }
              treaties_label

              treaties_icon {
                gatsbyImageData
                alt
              }
           
              fireside_label
              fireside_icon {
                gatsbyImageData
                alt
              }
              businesses_icon {
                gatsbyImageData
                alt
              }
              businesses_label
              }
            }
          }
        }
      }
      ...WhereAmIDetails
    }
    prismicMenu(lang: { eq: $lang }) {
      ...TopMenuFragment
      ...BottomMenuFragment
    }
  }
`

export default withPrismicPreview(WhereAmITemplate)
