import  React,{useState,useEffect,useCallback, Fragment, Suspense} from 'react'
import { graphql } from 'gatsby'
import { Container } from "../components/Container"
import * as s from './Map.module.scss';
import {
  GoogleMap,
} from "@react-google-maps/api";

import { useMap } from '../utils/mapProvider'
import MapLayerButton from '../components/map/MapLayerButton'
import { LayerComponent } from '../components/map/LayerComponent';

const defaultLocation = { lat: 60.219386, lng: -101.645961 };


export const Map = ({ slice }) => {

  const [map, setMap] = useState(null);  

  const onMapLoad = useCallback(function callback(gmap) {
    setMap(gmap)
  }, []);

  const mapLayers = slice.primary.map_layers?.document.data;
  
  const {
    isLoading,

  } = useMap();
 
  const layerData = 
  [
  {
    icon: mapLayers.treaties_icon,
    btnLabel: mapLayers.treaties_label,
    tableId: 'treaties'
  },
  {
    icon: mapLayers.city_icon,
    btnLabel: mapLayers.city_label,
    tableId: 'cities'
  },  
  
  
  {
    icon: mapLayers.communities_icon,
    btnLabel: mapLayers.communities_label,
    tableId: 'communities'
  },
  {
    icon: mapLayers.land_icon,
    btnLabel: mapLayers.land_label,
    tableId: 'territories'
  },
  {
    icon: mapLayers.schools_icon,
    btnLabel: mapLayers.schools_label,
    tableId: 'schools'
  },
  {
    icon: mapLayers.fireside_icon,
    btnLabel: mapLayers.fireside_label,
    tableId: 'chats'
  },
  {
    icon: mapLayers.businesses_icon,
    btnLabel: mapLayers.businesses_label,
    tableId: 'businesses'
  }];

  const [layer, setLayer] = useState(0);
  
 
  return (
    <section className={s.Map}>
      <div className={s.mapContainer}>
        <GoogleMap
          center={defaultLocation}
          zoom={3}
          options={{ mapId: "68283565ee8dd767", disableDefaultUI: true, fullscreenControl: true, zoomControl: true, keyboardShortcuts: false }}
          mapContainerClassName={s.MapContainer}
          onLoad={onMapLoad}
        >
          {!isLoading && (
            <LayerComponent layer={layerData[layer]}/>
          )}
        </GoogleMap>
      </div>
      <Container>
        <div className={s.FlexWrap}>
          <div className={s.mapFilterTitle}>
            <h2>{slice.primary.header}</h2>
          </div>
          {layerData?.map((x, index) => (
            <Fragment key={index}>
              <MapLayerButton
                id={index}
                icon={x.icon}
                label={x.btnLabel}
                isActive={layer===index}
                handleLayer={() => {
                  setLayer(index)
                }}
              />
            </Fragment>
          ))}   
        </div>
      </Container>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyMap on PrismicHomepageDataBodyMap {
    id
    primary {
      header
      map_layers {
        document {
          ... on PrismicMapKml {
            data {
              city_label
              city_kml {
                url
              }
              city_icon {
                gatsbyImageData
                alt
              }
              communities_label
              communities_icon {
                gatsbyImageData
                alt
              }
              land_label
              land_icon {
                gatsbyImageData
                alt
              }
              schools_label
              schools_icon {
                gatsbyImageData
                alt
              }
              treaties_label

              treaties_icon {
                gatsbyImageData
                alt
              }
           
              fireside_label
              fireside_icon {
                gatsbyImageData
                alt
              }
              businesses_icon {
                gatsbyImageData
                alt
              }
              businesses_label
            } 
          } 
        } 
      }
    }
  }
`
