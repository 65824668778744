import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText,PrismicLink } from '@prismicio/react'
import { Container } from "../components/Container"
import { FaArrowLeft, FaArrowRight} from 'react-icons/fa'
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  Image
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import useWindowDimensions  from "../components/useWindowDimensions";
import * as s from './VideoSlider.module.scss';

export const VideoSlider = ({ slice }) => {
  const totalSlides = slice.items.length;

  const slideWidth = 477;
  const slideHeight = 318
  const {height, width} = useWindowDimensions();
  const l_margin = Math.max(((width - 1440) / 2), 0);
  const adjusted_width = width? width - l_margin : 0.1;

  const sliderWidth = Math.max(Math.floor( adjusted_width / slideWidth ), 1);

  return (
    <section className={s.VideoSlider}>
      <Container>
        <div className={s.header}>
          <PrismicRichText field={slice.primary.h_rt?.richText}/>
        </div>
        <CarouselProvider
          naturalSlideWidth={slideWidth + 20}
          naturalSlideHeight={slideHeight}
          totalSlides={totalSlides}
          visibleSlides={(Math.max((width / 700),1)) + 0.5}
          infinite={true}
          step={1}
          isIntrinsicHeight={true}
        >
        <Slider classNameTray={s.sliderTray} classNameTrayWrap="slider-tray-wrap">
          {slice.items.map((item,index) => (
            <Slide index={index} key={`carousel: ${index}`} tabIndex={-1} classNameHidden="hidden-slide" className={s.slide}>
              <div dangerouslySetInnerHTML={{ __html: item.video?.html }} /> 
            </Slide>
          ))}
        </Slider>
        <ButtonBack className={s.btnNav} ><FaArrowLeft aria-label="left arrow" /></ButtonBack>
        <ButtonNext className={s.btnNav} ><FaArrowRight aria-label="right arrow" /></ButtonNext>
        </CarouselProvider>
      </Container>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyVideoSlider on PrismicHomepageDataBodyVideoSlider {
    id
    primary {
      h_rt {
        richText
      }
    }
    items {
      video {
        html
      }
    }
  }
`
