import * as React from 'react'
import { PrismicLink, PrismicRichText } from '@prismicio/react'
import {Card, Tag, MoreBtn, TileLogo} from '../templates/list.module.scss'
import { FaChevronRight, FaHouseUser } from 'react-icons/fa'
import { GatsbyImage } from 'gatsby-plugin-image'
import {Link} from 'gatsby'

export const ListItem = (props) => {
  const {type, name, image, description, link, label, colour, index} = props
  const style = {
    background: colour,
  }
  const desc = description?.length > 165 ? description?.slice(0,165) + "..." : description
  return (
    <Link to={link} className={Card} key={name + '-' +index}>
      {type && <span className={Tag} style={style}>{type}</span>}
      {image && 
        <div className={TileLogo}>
          {
            !image.childImageSharp  ?

              <>
                <FaHouseUser/>
              </>
            :
              <GatsbyImage 
                image={image.childImageSharp?.gatsbyImageData} 
                imgStyle={{objectFit: 'contain', objectPosition: 'left center'}}
                alt=""
              />                   
          } 
        </div>
      }
      <p><b>{name}</b></p>
      <p>{desc}</p>
      <div to={link} className={MoreBtn} >
        {label}<FaChevronRight/>
      </div>
    </Link>
  )
}
