import * as React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { Layout } from '../components/Layout'
import { FilterProvider } from '../utils/filterProvider'
import { Container } from '../components/Container'
import { PrismicRichText } from '@prismicio/react'
import { ListFilter } from '../components/ListFilter'
import { ListContent } from '../components/ListContent'
import { ListWrap, ListSectionContainer, ListHeaderContainer } from './list.module.scss'


const ListTemplate = ({ data }) => {
  if (!data) return null
  const pageContent = data.prismicList || {}
  const page = pageContent.data || {}
  const airtableNodes = data.allAirtable || {}
  const airtableData = airtableNodes?.nodes?.filter(node => node.table !== 'tblLvLuIFUJSVd3QS' || node.data.Approved === true) || {};
  const menu = data.prismicMenu || {}
  const { lang, type, url } = pageContent || {}
  const alternateLanguages = pageContent.alternate_languages || []
  const activeDoc = {lang,type,url,alternateLanguages,}

  return (
    <FilterProvider queryData={airtableData} labels={page} lang={lang}>
      <Layout 
        menu={menu.data} 
        activeDocMeta={activeDoc} 
        title={page.title}
        description={page.description?.text}
      >
        <section className={ListHeaderContainer}>
          <Container>
            <div>
              <h1>{page.title}</h1>
              <PrismicRichText field={page.description?.richText}/>
            </div>
          </Container>
        </section>
        <section className={ListSectionContainer}>
          <Container> 
            <div className={ListWrap}>
              <ListFilter data={page} lang={lang}/>
              <ListContent labels={page} lang={lang} />
            </div>
          </Container>
        </section> 
      </Layout>
    </FilterProvider>
  )
}

export const query = graphql`
  query listQuery($id: String, $lang: String) {
    prismicList(id: { eq: $id },lang: { eq: $lang }) {
      _previewable
      alternate_languages {
        uid
        type
        lang
      }
      lang
      url
      type
      id
      data {
        title 
        description {
          richText
          text
        }
        all_label
        communities_label
        firstnation_label
        inuit_label
        tribe_label
        learn_more_label
        metis_label
        territories_label
        treaties_label
        businesses_label
      }
      ...ListFilterFragment
    }
    allAirtable(filter: {
      table: {in: ["tblNbzH8fyCHl9Uak", "tblA1YdPobD3JN7iG", "tblD4d5rF383Bcbil", "tblLvLuIFUJSVd3QS"]}
    }) {
      nodes {
        data {
          Approved
        }
        ...ListContentFragment
      }
    }
    prismicMenu(lang: { eq: $lang }) {
      ...TopMenuFragment
      ...BottomMenuFragment
    }
  }
`

export default withPrismicPreview(ListTemplate)
