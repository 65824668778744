import React, { useState, useEffect, useContext, createContext } from 'react'

import slugify from './slugify'
import { getDistance } from 'geolib'
import { LoadScript } from '@react-google-maps/api';
const filterContext = createContext()

const lib = ["places"];
const idG = ["614f2b924c037cf7"];
const key = 'AIzaSyAkMp-DRCWbGCpLz9uqAUJq6-1sVPJBcQ0';

export const useFilter = () => useContext(filterContext)

export function FilterProvider({ children, queryData, labels, lang }) {
  const data = useFilterProvider(queryData, labels, lang)
  return (
    <filterContext.Provider value={data}>
      <LoadScript
        googleMapsApiKey={key} 
        libraries={lib} 
        mapIds={idG} 
        language={lang}
      >
        {children}
      </LoadScript>
    </filterContext.Provider>
  )
 
}

function useFilterProvider(queryData, labels, lang) {
  const typeFilter = [
    {
      value: 0,
      label: labels.all_label,
      id: 'All',
    },
    {
      value: 1,
      id: 'Communities',
      label: labels.communities_label,
      table: 'tblA1YdPobD3JN7iG'
    },
    {
      value: 2,
      id: 'Treaties',
      label: labels.treaties_label,
      table: 'tblNbzH8fyCHl9Uak'
    },
    {
      value: 3,
      id: 'Territories',
      label: labels.territories_label,
      table: 'tblD4d5rF383Bcbil'
    },
    {
      value: 4,
      id: 'Businesses',
      label: labels.businesses_label,
      table: 'tblLvLuIFUJSVd3QS'
    }
  ]
  const communityFilter = [
    {
      value: 0,
      id: 'First Nation',
      label: labels.firstnation_label
    },
    {
      value: 1,
      id: 'Métis Community',
      label: labels.metis_label
    },
    {
      value: 2,
      id: 'Inuit Community',
      label: labels.inuit_label
    },
    {
      value: 3,
      id: 'Native American Tribe',
      label: labels.tribe_label
    }
  ]
  const fixedData = queryData.map(i => keyMerge(i,lang.toLowerCase(),labels)).sort((a,b) => {
    if(a.name?.toLowerCase() < b.name?.toLowerCase()) return -1;
    if(a.name?.toLowerCase() > b.name?.toLowerCase()) return 1;
    return 0;
  }) 

  const [data, setData] = useState(fixedData)

  const [search, setSearch] = useState('')
  const [table, setTable] = useState(0)
  const [communities, setCommunities] = useState([])
  const [location, setLocation] = useState({distance: 50000, coords: null})

  const dataLength = data.length

  const handleTable = (val) => {
    if(val === table) return;
    setTable(val)
  }
  const handleLocation = (coords, dis) => {
    setLocation({distance: dis, coords: coords})
  }
  const handleCommunities = (val) => {
    if(val === communities) return;
    setCommunities(val)
  }
  const handleSearch = (val) => {
    if(val === search) return;
    setSearch(val.target.value)
  }
  useEffect(() => {
    updateData()
  },[search,table,communities,location])
 
  const updateData = () => {
    const td = applyTable(fixedData,table)
    const fd = applyFilter(td, communities, communityFilter)
    const ld = applyLocation(fd, location)
    const sd = applySearch(ld, search)
    setData(sd)
  }
  
  return {
    data,
    dataLength,
    typeFilter,
    communityFilter,
    table,
    handleTable,
    handleCommunities,
    handleSearch,
    handleLocation,
  }
}

function applySearch(data, searchValue){
  const search = searchValue.toLowerCase()
  if(!search.length > 0 ) return data;
  const sd = []
  data.map(
    item => {
      const name = item.name?.toLowerCase()
      const desc = item.description?.toLowerCase()
      if(name?.includes(search) || desc?.includes(search)) sd.push(item);
    }
  )
  return sd;
}

function applyTable(data, table){
  if(table == 0) return data
  const td = []
  data.map(i => {if(i.table == table) td.push(i) })
  return td
}
function applyFilter(data, community, comFilts){
  const isEmpty = community.every(x => x == 0)

  if(isEmpty) return data
  const td = []
  community.map((com,index) => {
    const comFilt = comFilts[index].id
    if(com) {
      data.map(i => {if(i.type == comFilt || i.communities?.some(x => x.data.Type == comFilt)) td.push(i) })
    }
  })
  return td
}
function applyLocation(data, location) {

  const {distance, coords} = location
  if (distance === null || coords === null || coords.lat === null) return data
  const td = []
  data.map(i => {
    if(i.table === 1 && getDistance(
      {latitude: Number(i.coordinates.lat), longitude: Number(i.coordinates.lng) },
      {latitude: Number(coords.lat), longitude: Number(coords.lng)}
    ) < distance) td.push(i)
    else if(i.table === 2 && isInsidePolygon(coords, pathify_treaties(i.coordinates)))td.push(i)
    else if(i.table === 3 && isInsidePolygon(coords, pathify_territory(i.coordinates))) td.push(i)
  })
  return td
}

function keyMerge(item,lang,labels){
  switch (item.table) {
    case 'tblA1YdPobD3JN7iG': {
      const label = {
        'First Nation': labels.firstnation_label,
        'Inuit Community': labels.inuit_label,
        'Métis Community': labels.metis_label,
        'Native American Tribe': labels.tribe_label,
      }
      const image = item.data.Logo?.localFiles?.length > 0 ? item.data.Logo?.localFiles[0] : null
      return {
        type: item.data.Type,
        label: label[item.data.Type],
        table: 1,
        name: item.data.Name,
        description: item.data.Description,
        link: `/${lang.slice(0,2)}/communities/${slugify(item.data.Name)}`,
        colour: '#FFE45C',
        communities: null,
        image: image,
        coordinates: {lat: item.data.Latitude, lng: item.data.Longitude}
      }
    }
    case 'tblNbzH8fyCHl9Uak': {
      return {
        type: 'Treaties',
        label: labels.treaties_label,
        table: 2,
        name: lang === 'fr-ca' ? item.data.Treaty_Name_FR : item.data.Treaty_Name_EN,
        description: lang === 'fr-ca' ? item.data.Field_8  : item.data.Field_7,
        link: `/${lang.slice(0,2)}/treaties/${item.data.Slug}`,
        colour: '#B0D8D1',
        communities: item.data.Communities,
        image: null,
        coordinates: item.data.Coordinates ? JSON.parse(item.data.Coordinates)[0] : null
      }
    }
    case 'tblD4d5rF383Bcbil': {
      return {
        type: 'Territories',
        label: labels.territories_label,
        table: 3,
        name: item.data.Name,
        description: null,
        link: `/${lang.slice(0,2)}/territories/${item.data.Slug}`,
        colour: '#DB6642',
        communities: item.data.Communities,
        image: null,
        coordinates: item.data.Coordinates ? JSON.parse(item.data.Coordinates)[0] : null
      }
    }
    case 'tblLvLuIFUJSVd3QS': {
      const image = item.data.Logo?.localFiles?.length > 0 ? item.data.Logo?.localFiles[0] : null
      return {
        type: 'Businesses',
        label: 'Businesses',
        table: 4,
        name: item.data.Name,
        description: item.data.Description,
        link: `/${lang.slice(0,2)}/businesses/${slugify(item.data.Name)}`,
        colour: '#F8E4D2',
        communities: item.data.Community,
        image: image,
        coordinates: {lat: item.data.Latitude, lng: item.data.Longitude}
      }
    }
    default: 
      return {
        type: null,
        label: null,
        table: 0,
        name: null,
        description: null,
        link: '/',
        colour: '#ffffff',
        image: null,
        coordinates: null 
      }
  }
}
function isInsidePolygon(point, vs){
  if(!point) return false;
  var x = point.lat, y = point.lng;

    var inside = false;
    for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
        var xi = vs[i].lat, yi = vs[i].lng;
        var xj = vs[j].lat, yj = vs[j].lng;
        
        var intersect = ((yi > y) != (yj > y))
            && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
        if (intersect) inside = !inside;
    }
    return inside;
}
function pathify_treaties(list) {
  const cur = []
  const tList = list || []
  tList?.map(item => 
    cur.push({lat: Number(item[1]), lng: Number(item[0])})  
  )
  return cur;
}
function pathify_territory(list) {
  const cur = []
  const tList = list || []
  tList?.map(item => 
    cur.push({lat: item[1], lng: item[0]})  
  )
  return cur;
}
