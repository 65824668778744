import * as React from 'react'
import {  graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { SliceZone } from '@prismicio/react'
import { Layout } from '../components/Layout'
import { components } from '../slices'

import { MapProvider } from '../utils/mapProvider'

const HomepageTemplate = ({ data }) => {

  
  if (!data) return null

  const homepage = data.prismicHomepage || {}
  const menu = data.prismicMenu || {}
  const filteredNodes = data.allAirtable.nodes.filter(node => node.table !== 'tblLvLuIFUJSVd3QS' || node.data.Approved === true);
  const combinedNodes = [...filteredNodes, ...data.allAirtableChats.nodes, ...data.allAirtableSchools.nodes];
  const mapLayers = homepage?.data?.map_layers?.document.data;
  const { lang, type, url } = homepage || {}
  const alternateLanguages = homepage.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }

  return (
    <MapProvider data={mapLayers} airtableData={combinedNodes} id={0} lang={lang.toLowerCase().slice(0,2)}>
      <Layout menu={menu.data} activeDocMeta={activeDoc}>
        <SliceZone slices={homepage.data?.body} components={components} context={{lang: lang.toLowerCase().slice(0,2)}}/>  
      </Layout>
    </MapProvider>
  )
}
//Treaties, Communities, Territories, Businesses
//Chats
//US Schools, CA Schools
export const query = graphql`
  query homepageQuery($lang: String) {
    allAirtable(filter: {
      table: {in: ["tblNbzH8fyCHl9Uak", "tblA1YdPobD3JN7iG", "tblD4d5rF383Bcbil", "tblLvLuIFUJSVd3QS"]}
    }) {
      nodes {
        table
        data {
          Name
          Syllabics
          Pronunciation
          Color
          ID
          Treaty_Name_EN
          Coordinates
          Latitude
          Longitude
          Slug
          Description_Alt: Field_7
          Description
          Approved
          Product_Categories
        }
      }
    }
    allAirtableChats {
      nodes {
        table
        data {
          Name
          Web_URL
          Community_Name
          Latitude
          Longitude
          Description: Subtitle

        }
      }
    }
    allAirtableSchools {
      nodes {
        table
        data {
          Name
          Link: URL
          Latitude
          Longitude
          Start_Date
          End_Date
          Operating_Dates
          Location
          Image
        }
      }
    }
    prismicHomepage(lang: { eq: $lang }) {
      _previewable
      alternate_languages {
        uid
        type
        lang
      }
      lang
      url
      type
      data {
        map_layers {
          document {
            ... on PrismicMapKml {
              data {
                city_kml {
                  url
                }
              }
            }
          }
        }
        body {
          ... on PrismicSliceType {
            id
            slice_type
            slice_label
          }
          ...HomepageDataBodyMap
          ...HomepageDataBodyTextImage
          ...HomepageDataBodyVideoSlider
          ...HomepageDataBodyWhyAcknowledge
        }
      }
    }
    prismicMenu(lang: { eq: $lang }) {
      ...TopMenuFragment
      ...BottomMenuFragment
    }
  }
`

export default withPrismicPreview(HomepageTemplate)
