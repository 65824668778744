import { Map } from './Map'
import { TextImage } from './TextImage'
import { VideoSlider } from './VideoSlider'
import { WhyAcknowledge } from './WhyAcknowledge'
import { Contact } from './Contact'
import { Text } from './Text'
import { FAQ } from './Faq'

export const components = {
  map: Map,
  text_image: TextImage,
  video_slider: VideoSlider,
  why_acknowledge: WhyAcknowledge,
  contact: Contact,
  text: Text,
  faq: FAQ,
}
