// extracted by mini-css-extract-plugin
export var BtnOrange = "TextImage-module--BtnOrange--aa61e";
export var Container = "TextImage-module--Container--4fb01";
export var FlexWrap = "TextImage-module--FlexWrap--f6259";
export var H1 = "TextImage-module--H1--465d4";
export var H2 = "TextImage-module--H2--0e7fe";
export var H3 = "TextImage-module--H3--60fea";
export var H4 = "TextImage-module--H4--bdc54";
export var H5 = "TextImage-module--H5--2eabd";
export var H6 = "TextImage-module--H6--940be";
export var TextImage = "TextImage-module--TextImage--a6bae";
export var blockImg = "TextImage-module--block-img--ae8c4";
export var contentPage = "TextImage-module--contentPage--fccae";
export var copyWrap = "TextImage-module--copyWrap--d20a1";
export var imageWrap = "TextImage-module--imageWrap--137eb";
export var reverse = "TextImage-module--reverse--6f203";
export var sliderTray = "TextImage-module--sliderTray--9c5ae";