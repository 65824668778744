import * as React from 'react'
import { graphql } from 'gatsby'
import { navigate } from 'gatsby-link'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText,PrismicLink } from '@prismicio/react'
import { Container } from "../components/Container"
import * as s from './Contact.module.scss';

export const Contact = ({ slice }) => {
  const thank_you = "/thankYou/";

  function encode(data) {
    return Object.keys(data)
      .map(
        (key) =>
          encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  }
  const [state, setState] = React.useState({})
  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/en/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }
  return (
    <section className={s.Contact}>
      <Container>
        <div className={s.headerWrap}>
          <PrismicRichText field={slice.primary.h_rt?.richText}/>
        </div>
        <form
          method="POST" 
          netlify-honeypot="bot-field" 
          data-netlify="true" 
          name="contact"
          onSubmit={handleSubmit}
          action={thank_you}
        >
          <p hidden>
            <label>
              Don’t fill this out: <input name="bot-field" onChange={handleChange} />
            </label>
          </p>
          <input type="hidden" name="form-name" value="contact" onChange={handleChange}/>
          <label htmlFor="name">{slice.primary.name_label}</label>
          <input type="text" id="name" name="name"  required onChange={handleChange}/>
          <label htmlFor="email">{slice.primary.email_label}</label>
          <input type="text" id="email" name="email" required onChange={handleChange}/>
          <label htmlFor="message">{slice.primary.message_label}</label>
          <textarea id="message" name="message" rows="6"  required onChange={handleChange}/>
          <input className={s.BtnOrange} type="submit" value={slice.primary.submit_label} onChange={handleChange}/>
        </form>
      </Container>
    </section>
  )
}

export const query = graphql`
  fragment PageDataBodyContact on PrismicPageDataBodyContact {
    id
    primary {
      h_rt {
        richText
      }
      name_label
      email_label
      message_label
      submit_label
    }
  }
`
