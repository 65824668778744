import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText,PrismicLink } from '@prismicio/react'
import { Container } from "../components/Container"
import * as s from './TextImage.module.scss';

export const TextImage = ({ slice }) => {
  const image = (
    <div className={s.imageWrap}>
      <GatsbyImage
        image={slice.primary.img?.gatsbyImageData}
        alt={slice.primary.img?.alt || ""}
      />
    </div>
  );
  const text = (
    <div className={s.copyWrap}>
      <PrismicRichText field={slice.primary.rt?.richText}/>
    </div>
  );
  return (
    <section className={s.TextImage} style={{background: slice.primary.bg_color}}>
      <Container>
        <div className={`${s.FlexWrap} ${slice.primary.image_side? s.reverse : ""}`}>
          {slice.primary.image_side ? ( <>{text} {image}</> ) : (<> {image} {text} </>)}
        </div>
      </Container>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyTextImage on PrismicHomepageDataBodyTextImage {
    id
    primary {
      bg_color
      image_side
      rt {
        richText
      }
      img {
        gatsbyImageData(
          width: 217
          placeholder: BLURRED
        )
        alt 
      }

    }
  }
`
