import * as React from 'react'
import { Link, graphql } from 'gatsby'
import { PrismicLink, PrismicText } from '@prismicio/react'
import { GatsbyImage ,StaticImage } from 'gatsby-plugin-image'
import { Container } from './Container'

import * as s from './BottomMenu.module.scss'


export const BottomMenu = ({ menu, activeDocMeta }) => {

  
  return (
    <footer className={s.Footer}>
      <Container>
        <div className={s.Row}>
          <h2>{menu?.brought_by_label}</h2>
          <div className={s.cardRow}>
            {menu?.brought_by.map((item,index) => (
              <PrismicLink className={s.card} key={`bb-${index}`}  href={item.link?.url}>
                <GatsbyImage
                  image={item.img?.gatsbyImageData}
                  alt={item.img?.alt || ""}
                />
              </PrismicLink>
            ))}
          </div>
        </div>
        <div className={s.Row}>
          <h2>{menu?.sponsored_by_label}</h2>
          <div className={s.cardRow}>
            {menu?.sponsored_by.map((item,index) => (
              <PrismicLink className={s.card} key={`bb-${index}`} href={item.link?.url}>
                <GatsbyImage
                  image={item.img?.gatsbyImageData}
                  alt={item.img?.alt || ""}
                />
              </PrismicLink>
            ))}
          </div>
        </div>
      </Container>
    </footer>

  )
}

export const query = graphql`
  fragment BottomMenuFragment on PrismicMenu {
    _previewable
    type
    lang
    data {
      logo_full {
        gatsbyImageData(
            placeholder: BLURRED
          )
        alt 
      }
      brought_by_label
      brought_by {
        img {
          gatsbyImageData(
            placeholder: BLURRED
          )
          alt
        }
        link {
          url
        }
      }
      sponsored_by_label
      sponsored_by {
        img {
          gatsbyImageData(
            placeholder: BLURRED
          )
          alt 
        }
        link {
          url
        }
      }

    }
  }
`
