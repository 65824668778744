// extracted by mini-css-extract-plugin
export var BtnOrange = "WhereAmI-module--BtnOrange--43f49";
export var Container = "WhereAmI-module--Container--6d751";
export var Details = "WhereAmI-module--Details--6d9f2";
export var FlexWrap = "WhereAmI-module--FlexWrap--16ae2";
export var H1 = "WhereAmI-module--H1--c721b";
export var H2 = "WhereAmI-module--H2--cf7af";
export var H3 = "WhereAmI-module--H3--bc7cd";
export var H4 = "WhereAmI-module--H4--df8c8";
export var H5 = "WhereAmI-module--H5--3b609";
export var H6 = "WhereAmI-module--H6--70257";
export var Map = "WhereAmI-module--Map--207a0";
export var WhereAmIContainer = "WhereAmI-module--WhereAmIContainer--56257";
export var blockImg = "WhereAmI-module--block-img--cc2b8";
export var contentPage = "WhereAmI-module--contentPage--c819f";
export var icon = "WhereAmI-module--icon--4eb3b";
export var infoBox = "WhereAmI-module--infoBox--c3f04";
export var infoHeader = "WhereAmI-module--infoHeader--f3c19";
export var infoWrap = "WhereAmI-module--infoWrap--870a3";
export var loading = "WhereAmI-module--loading--a0702";
export var loadingSpinner = "WhereAmI-module--loadingSpinner--68f2e";
export var locationCTA = "WhereAmI-module--locationCTA--db4cc";
export var search = "WhereAmI-module--search--45fee";
export var sliderTray = "WhereAmI-module--sliderTray--12e5a";
export var spinner = "WhereAmI-module--spinner--59b87";