import React from 'react'

const slugify = str => {
  if(!str) return null
  return str
    .toLowerCase()
    .trim()
    .replace(/[^A-Za-zÀ-ÖØ-öø-ÿ0-9\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '')
  }

export default slugify