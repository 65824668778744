import React, { Fragment, useEffect, useState } from 'react'
import { infoBox } from '../../slices/Map.module.scss'
import {
  Polygon,
  InfoWindow
} from "@react-google-maps/api";
import { useMap } from '../../utils/mapProvider';
import { PrismicLink } from '@prismicio/react';

const CustomPolygons = ({data, isActive}) => {
  const {isInsidePolygon} = useMap();
  const [clickCoord, setClickCoord] = useState(null);
  const [activePoly, setActivePoly] = useState([]);
  const handleClickCoord = (ev) => {
    const coord = {lat: ev.latLng.lat(), lng:ev.latLng.lng() };
    const list = [];
    data.map(x => {
      if(isInsidePolygon(coord, x.coordinates)){
        list.push(x)
      }
    })
    const map = new Map();
    list.forEach(item => {
      if(!map.has(item.type)){
        map.set(item.type, { type: item.type, data: [] });
        }
        map.get(item.type).data.push({ ...item });
    });

    const groupedByType = Array.from(map.values());

    setClickCoord(coord);
    setActivePoly(groupedByType)
  }
  useEffect(() => {
    if(!isActive){
      setActivePoly([])
    }
  }, [isActive]);
  if(!isActive || !data) return <></>;
  return (
    <>
      {data.map((x, index) => (
        <Fragment key={`${x.name} - ${index}`}>
          {x.coordinates && x.coordinates.length > 1 &&
            <Polygon
              paths={x.coordinates}
              visible={isActive}
              onClick={handleClickCoord}
              options = {{fillColor: x.color, strokeWeight: 0.5}}
            />
          }
        </Fragment>
      ))}
      {activePoly.length > 0 && (
        <InfoWindow position={clickCoord}>
          <div className={infoBox} style={{gap: 12}}>
            {activePoly?.map((x) => (
              <Fragment key={x.type}>
                <p><strong>{x.type}</strong></p>
                <ul style={{marginLeft: 0, marginTop: 4, marginBottom: -4}}>
                  {x.data.map((y, i) => (
                    <li key={`${x.type} - ${i}`} style={{ marginBottom: 4}}>
                      <PrismicLink href={y.link} key={`${y.name} - ${i} - link`}>{y.name}</PrismicLink>
                    </li>
                  ))}
                  
                </ul>
              </Fragment>
              
            ))}
          </div>
        </InfoWindow>
      )}
      
    </>
  )
}

export default CustomPolygons;