import React, { useRef, useState} from 'react'
import { Autocomplete } from '@react-google-maps/api'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import { graphql } from 'gatsby'

import * as s from '../templates/list.module.scss'
import { useFilter } from '../utils/filterProvider'
import { PrismicRichText } from '@prismicio/react'

import { FaChevronDown, FaChevronUp, FaSearch, FaSlidersH} from 'react-icons/fa'
import { FaCrosshairs } from 'react-icons/fa'
import { IoMdClose} from 'react-icons/io'
import useWindowDimensions from './useWindowDimensions'

const marks = {
  50: {
    style: {
      left: 0,
      transform: 'translate(0)',
    },
    label:<strong>50km</strong> 
  },
  500: {
    label:<strong>500km</strong> 
  },
  1000: {
    style: {
      left: '100%',
      transform: 'translate(-100%)',
    },
    label:<strong>1000km</strong> 
  },
}

export const ListFilter = ({data, lang}) => {
  const { handleLocation, handleSearch, dataLength, handleCommunities, communityFilter} = useFilter()
  const emptyCom = Array(communityFilter.length).fill(false)
  const [autoComplete, setAutoComplete] = useState(null)

  const [community, setCommunity] = useState(emptyCom)

  const [distance, setDistance ] = useState(50)
  const [coords, setCoords] = useState(null)

  const [open, setOpen] = useState(false)

  const mobileWidth = 960;
  const { width} = useWindowDimensions();
  const isMobile = width && (width < mobileWidth);

  const comsActive = community.reduce((total, x) => total + x)
  const inputRef = useRef()

  const onLoad = (autocomplete) => {
    setAutoComplete(autocomplete)
  }
  const handleLocalCommunity = (e) => {
    const val = e.target.value
    var tArray = community.map((x,i) => {
      if(i == val) return !x;
      else return x;
    })
    setCommunity(tArray)
    handleCommunities(tArray)
  }
  const handleCoords = () => {
    const place = autoComplete.getPlace()
    const tCoords = place?.geometry?.location ? {
      lat: place.geometry.location.lat(), 
      lng: place.geometry.location.lng()
    } : null
    if(tCoords != coords && tCoords != null) {
      setCoords(tCoords)
      if(distance != null){
        handleLocation(tCoords, distance*1000)
      }
    }
  }

  const handleDistance = (e) => {
    const tDis = e 
    if(tDis != distance && tDis != null) {
      setDistance(tDis)
      if(coords != null) {
        handleLocation(coords, tDis*1000)
      }
    } 
  }

  // const applyFilter = () => {
  //   handleCommunities(community);
  //   if(location != null && distance != null) {
  //     handleLocation(coords, distance*1000)
  //   }
  //   if(isMobile && open) {
  //     setOpen(false)
  //   }
  // }
  const applyClear = () => {
    setCommunity(emptyCom)
    handleCommunities(emptyCom)
    setDistance(50)
    setCoords(null)
    autoComplete.set('place',null)
    handleLocation(null, 50*1000)
    inputRef.current.value = null
    if(isMobile && open) {
      setOpen(false)
    }
  }


  return (
    <div className={isMobile? open ? s.FilterMenu : s.FilterMenuClosed : s.FilterDesktop}>
      {isMobile && open && (
        <button className={s.hamburger} onClick={() => setOpen(false)} tabIndex={0} aria-label="Close">
          
          <IoMdClose/>
        </button>
      )}
      <span aria-live="polite" className={s.Results} id="results">{dataLength} {data.available_results_label}</span>
      <div className={s.SearchFilter}>
        <div className={s.Searchbar}>
          <input 
            type='text' 
            placeholder={data.search___placeholder} 
            onChange={handleSearch}
            role="search" 
            aria-label="Search"
            aria-describedby="results"
          />
          <FaSearch/>
          
        </div>
        {isMobile && !open && ( 
          <button onClick={() => setOpen(true)} tabIndex={0} className={s.FilterListBtn} aria-label="Filter">
            {data.filter___label} {comsActive > 0 ?
              "(" + comsActive + ")" : "   "
            }<FaSlidersH/>
          </button>
        )}
      </div>
      
      <div className={s.FilterWrap}>
      
        <details className={s.FilterDropdown} open>
          <summary style={{display: 'flex', gap: 5}}>
            <PrismicRichText field={data.location___header?.richText}/>
            
            <div className={s.ArrowUp}><FaChevronUp/></div>
            <div className={s.ArrowDown}><FaChevronDown/></div>
          </summary>
          <div className={s.LocationOptions}>
            <div>
              <label htmlFor='location'>{data.location___search_label}</label>
              <div className={s.LocationInputWrapper}>
                <Autocomplete
                  onPlaceChanged={handleCoords}
                  onLoad={onLoad}
                >
                  <input id="location" className={s.LocationInput} ref={inputRef}/>
                </Autocomplete>
                <FaCrosshairs/>
              </div>
            </div>
          
            <div>
              <label htmlFor="dis-slider">
                {data.location___distance_label}
              </label>
              <Slider
                step={100}
                min={50}
                max={1000}
                marks={marks}
                value={distance}
                style={{marginBottom: 20}}
                onChange={handleDistance}
                dots={false}
                id='dis-slider'
                handleStyle={{
                  color: '#468A7F',
                  background: '#468A7F',
                }}
                dotStyle={{
                  display: 'none'
                }}
                trackStyle={{
                  color: '#468A7F',
                  background: '#468A7F',
                }}
              />  
            </div>
          </div>
        </details>

        <details className={s.FilterDropdown} open>
          <summary style={{display: 'flex', gap: 5}}>
            <PrismicRichText field={data.community___header?.richText}/>{comsActive > 0 && (
              <span>( {comsActive} )</span>
            )}
            <div className={s.ArrowUp}><FaChevronUp/></div>
            <div className={s.ArrowDown}><FaChevronDown/></div>
          </summary>
          <div className={s.LabelOptions}>
            {communityFilter.map((f,i) => (
              <label style={{display: 'flex', alignItems: 'flex-start'}} key={i} htmlFor={f.value}>
                <input 
                  type="checkbox" 
                  checked={community[f.value]} 
                  id={f.value}
                  value={f.value} 
                  style={{width: 20}} 
                  onChange={e => handleLocalCommunity(e,i)} 
                  aria-label={f.label}
                />
                {f.label}
              </label>     
            ))}
          </div>
        </details>

        <div className={s.FilterBtns}>
          {/* <button onClick={() => applyFilter()} className={s.BtnA}>
            {data.filter___apply_btn_label}
          </button> */}
          <button onClick={() => applyClear()} className={s.BtnB}>
            {data.filter___clear_filter_btn}
          </button>
        </div>
        
      </div>
    </div>
  )
}
export const query = graphql`
  fragment ListFilterFragment on PrismicList {
    data {
      available_results_label
      community___header {
        richText
      }
      filter___label
      filter___apply_btn_label
      filter___clear_filter_btn
      location___search_label
      location___distance_label
      location___header {
        richText
      }
      location___search_placeholder
      region___header {
        richText
      }
      search___placeholder
    }
  }
`