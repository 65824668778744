import * as React from 'react'
import { Link, graphql } from 'gatsby'
import { PrismicLink, PrismicText } from '@prismicio/react'
import { GatsbyImage ,StaticImage } from 'gatsby-plugin-image'
import { Container } from './Container'
import useWindowDimensions from './useWindowDimensions'
import { LanguageSwitcher } from './LanguageSwitcher'
import { CgMenu, CgMenuRightAlt} from 'react-icons/cg'
import * as s from './TopMenu.module.scss'


export const TopMenu = ({ menu, activeDocMeta }) => {

  const mobileWidth = 960;
  const {height, width} = useWindowDimensions();
  const isMobile = width && width < mobileWidth? true : false;

  const [active, setActive] = React.useState(false);
  const [expand, setExpand] = React.useState(true);
  const [mobile, setMobile] = React.useState(null);

  const handleClick = () => {
    setActive((active) => !active);  
  }
  const handleExpand = () => {
    setExpand((expand) => !expand);
  }

  React.useEffect(() => {
    width < mobileWidth ? setMobile(true) : setMobile(false);
    setActive(false);
  }, [width]);

  const mobileSideMenu = (
    <div className={`${s.menu} ${s.visible}`}>
      <PrismicLink href="/en/" className={s.logoWrap}>
        <GatsbyImage
          image={menu.logo?.gatsbyImageData}
          alt={menu.logo?.alt || ""}
          className={s.logo}
        />
      </PrismicLink>
      <div className={s.menuLinks}>
        {menu.mobile_menu_items?.map((item,index) => (
          <PrismicLink href={item.link?.url} key={`ML-${index}`}>
            {item.label}
          </PrismicLink>  
        ))}
        <LanguageSwitcher activeDocMeta={activeDocMeta}/>
      </div>
    </div>

  )
  const mobileBottomMenu = (
    <>
    {active === true? mobileSideMenu : null}
    <div className={s.fixedMenu}>
      <div className={s.FlexWrap}>
        <button onClick={handleClick} className={s.iconLink} aria-labelledby='aria-label-menu'>
          <span className={s.icon} >{active? (
            <>
              <CgMenuRightAlt aria-label='Expand' id='aria-label-menu'/><span hidden>Expand</span>
            </>) : (<> 
              <CgMenu aria-label='Collapse' id='aria-label-menu'/><span hidden>Collapse</span></>)}
          </span>
          <span className={s.label}>Menu</span>
        </button>
        {menu.menu_links?.map((item,index) => (
          <PrismicLink href={item.link?.url} className={s.iconLink} key={`link-${index}`}>
            <GatsbyImage
              image={item.icon?.gatsbyImageData}
              alt={item.icon?.alt || ""}
              className={s.icon}
            />
            <span className={s.label}>{item.link_label}</span>
          </PrismicLink>
        ))}
      </div>
    </div>
    </>
    
  )
  const deskSideMenu = (
    <div className={`${s.sideMenu} ${expand ? s.sideActive : s.sideInactive}`}>
      <div className={s.FlexWrap}>
        <PrismicLink href="/en/" className={s.logoWrap}>
          <StaticImage
            src="./../images/favicon.png"
            height={50}
            alt={menu.logo?.alt || ""}
            className={s.logo}
          />
        </PrismicLink>
        <button onClick={handleExpand} className={s.iconLink} aria-label={expand? 'collapse sidebar': 'expand sidebar'}>
          <span className={s.icon}>{expand? <CgMenuRightAlt/> : <CgMenu/>}</span>
          <span hidden>{expand? 'collapse sidebar': 'expand sidebar'}</span>
        </button>
      </div>
      <div className={s.FlexCol}>
        {menu.menu_links?.map((item,index) => (
            <PrismicLink href={item.link?.url} className={s.iconLink} key={`link2-${index}`}>
              <div className={s.iconWrap}>
                <GatsbyImage
                  image={item.icon?.gatsbyImageData}
                  alt={item.icon?.alt || ""}
                  className={s.icon}
                />
              </div>
              <span className={`${s.label} ${expand ? "" : s.hidden}`}>{item.link_label}</span>
            </PrismicLink>
          ))}
      </div>
      <div className={s.SubFlexCol}>
        {menu.mobile_menu_items?.map((item,index) => (
          <PrismicLink href={item.link?.url} key={`ML2-${index}`} className={`${s.labelLink} ${expand ? "" : s.hidden}`}>
            {item.label}
          </PrismicLink>
        ))}
        <div  className={`${expand ? s.paddedSide : s.hidden}`}>
          <LanguageSwitcher activeDocMeta={activeDocMeta}/>
        </div>
      </div>
    </div>
  )
    

  return (
    <header className={s.header}>
      <a className={s.skip_button} href="#main">   
        Skip to Content
      </a>
      <PrismicLink href={"/en/"} className={s.fixedLogo}>
        <GatsbyImage
          image={menu.logo?.gatsbyImageData}
          alt={menu.logo?.alt || ""}
          className={s.logo}
        />
      </PrismicLink>
      {mobileBottomMenu}
      {deskSideMenu }
    </header>

  )
}

export const query = graphql`
  fragment TopMenuFragment on PrismicMenu {
    _previewable
    type
    lang
    data {
      logo {
        gatsbyImageData(
        width: 216
        )
        alt
      }
      mobile_menu_items {
        link {
          url
        }
        label
      }
      menu_links {
        link_label 
        link {
          url 
        }
        icon {
          gatsbyImageData(
            placeholder: BLURRED
          )
          alt
        }
      }
    }
  }
`
