import React, {useState, useEffect} from 'react'
import { graphql } from 'gatsby'
import { useFilter } from '../utils/filterProvider'
import { ListItem } from './ListItem'
import ReactPaginate from 'react-paginate'

import {
  ListGrid, 
  ContentWrap, 
  Pagination, 
  PaginationPage, 
  ActivePage,
  ActiveListBtn,
  ListBtn,
  BtnBox,
} from '../templates/list.module.scss'


export const ListContent = ({labels, lang}) => {
  const { data, typeFilter, table, handleTable } = useFilter()

  const [currentPage, setCurrentPage] = useState(0)
  const [itemOffset, setItemOffset] = useState(0)
  const itemsPerPage = 6
  const endOffset = itemOffset + itemsPerPage

  const currentItems = data.slice(itemOffset, endOffset)
  const pageCount = Math.ceil(data.length / itemsPerPage)

  const handleActiveList = (event) => {
    handleTable(event.target.value)
  }

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    setItemOffset(newOffset);
    setCurrentPage(event.selected)
  }
  useEffect(() => {
    setItemOffset(0);
    setCurrentPage(0);
  },[data])

  return (
    <div className={ContentWrap}>
      <div className={BtnBox} role="list" aria-label="Treaty, Territory, or Community Filter">
        {typeFilter?.map(option => (
          <button 
            key={option.value} 
            onClick={handleActiveList} 
            value={option.value}
            role="listitem"
            className={option.value != table ? ListBtn : ActiveListBtn}
          >
            {option.label}
          </button>
        ))}
      </div>
      
      <div className={ListGrid} role="list">
        {currentItems && 
          currentItems.map((x,i) => {
            const {label,image, name,description,link,colour} = x
            return (
              <ListItem
                role="listitem"
                type={label}
                name={name}
                description={description}
                link={link}
                label={labels.learn_more_label}
                colour={colour}
                index={i}
                key={i}
                image={image}
              />
            )    
          })
        }
      </div>
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        previousLabel="<"
        renderOnZeroPageCount={null}
        pageRangeDisplayed={2}
        marginPagesDisplayed={1}
        pageCount={pageCount}
        onPageChange={handlePageClick}
        forcePage={currentPage}
        className={Pagination}
        pageClassName={PaginationPage}
        breakClassName={PaginationPage}
        previousClassName={PaginationPage}
        nextClassName={PaginationPage}
        activeClassName={ActivePage}
      />
    </div>
  )
}
export const query = graphql`
  fragment ListContentFragment on Airtable {
    table
    data {
      Color
      Name
      Slug
      Treaty_Name_EN
      Treaty_Name_FR
      Type
      Field_7
      Field_8
      Description
      Coordinates
      Latitude
      Longitude
      Logo {
        localFiles {
          childImageSharp {
            gatsbyImageData(placeholder: NONE)
          }
          extension
          src: publicURL
        }
        raw {
          url
        }
      }
      Community {
        data {
          Type
          Name
        }
      }
      Communities {
        data {
          Type
          Name
        }
      }
    }
  }
`
