import React, { useEffect, useState } from 'react'
import {iconBox, infoBox, layerActive, icon as iconStyle} from '../../slices/Map.module.scss'
import {
  MarkerClusterer,
  Marker,
  InfoWindow
} from "@react-google-maps/api";
import MarkerIcon from "./../../images/marker-1.png"
import MarkerIconAlt from "./../../images/marker-1-alt.png"
import ChatMarker from "./../../images/chat-marker.png"
import m1 from '../../images/m/m1.svg'
import m2 from '../../images/m/m2.svg'
import m3 from '../../images/m/m3.svg'
import m4 from '../../images/m/m4.svg'
import m5 from '../../images/m/m5.svg'
import { PrismicLink } from '@prismicio/react';

const options = {
  minimumClusterSize: 3,
  styles:
  [
    {
      url: m1,
      height: 53,
      lineHeight: 53,
      width: 53,
    },
    {
      url: m2,
      height: 56,
      lineHeight: 56,
      width: 56,
    },
    {
      url: m3,
      height: 66,
      lineHeight: 66,
      width: 66,
    },
    {
      url: m4,
      height: 78,
      lineHeight: 78,
      width: 78,
    },
    {
      url: m5,
      height: 90,
      lineHeight: 90,
      width: 90,
    },
  ]
}
const tableIcons = {
  'Cities': MarkerIcon,
  'Community': MarkerIconAlt,
  'School': MarkerIcon,
  'Chat': ChatMarker,
  'Business': MarkerIconAlt,
}

function spreadMarkers(data) {

  const coordMap = new Map();
  data.forEach(item => {
    const key = `${item.coordinates.lat},${item.coordinates.lng}`;
    coordMap.set(key, (coordMap.get(key) || 0) + 1);
  });

  const radius = 0.00025; 
  const groupIndexMap = new Map();

  return data.map((item, index) => {
    const key = `${item.coordinates.lat},${item.coordinates.lng}`;
    const count = coordMap.get(key);
    if (count > 1) {

      const groupIndex = groupIndexMap.get(key) || 0;
      groupIndexMap.set(key, groupIndex + 1);

      const angle = (groupIndex / count) * 2 * Math.PI;

      return {
        ...item,
        coordinates: {
          lat: item.coordinates.lat + radius * Math.cos(angle),
          lng: item.coordinates.lng + radius * Math.sin(angle),
        },
      };
    } else {
      return item;
    }
  });
}

const CustomMarkerClusterer = ({data, isActive}) => {
  if(!isActive || !data) return <></>;
  const newData = spreadMarkers(data);
  const [activeMarker, setActiveMarker] = useState(null);
  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  useEffect(() => {
  
    if (!isActive) {
      setActiveMarker(null);
    }
  }, [isActive]);
  
  return (
    <MarkerClusterer options={options}>
      {(clusterer) =>
        newData.map((x, index) => (
          <Marker 
            position={x?.coordinates}
            key={`${x?.name} - ${index}`}
            onClick={() => handleActiveMarker(index)}
            icon={tableIcons[x?.type] || tableIcons['Cities']}
            visible={isActive}
            clusterer={clusterer}
          >
            {activeMarker === (index) && (
              <InfoWindow 
                onCloseClick={() => setActiveMarker(null)}
              >
                <div className={infoBox}>
                  <p><strong>{x?.name}</strong></p>
                  {x?.subtitle && 
                    <p style={{fontStyle: 'italic'}}>{x.subtitle}</p>
                  }
                  {x?.description && 
                    <p>{x?.description}</p>
                  }
                  {x?.descriptionHTML &&
                    <div className="inner" dangerouslySetInnerHTML={{__html: x.descriptionHTML}}/>
                  }
                  {x?.image && 
                    <img src={x.image} alt={''} style={{maxWidth: 200}}/>
                  }
                  {
                    x?.link &&
                    <PrismicLink href={x.link} style={{marginTop: 12}}>Learn More</PrismicLink>
                  }
                </div>
              </InfoWindow>
            )}  
          </Marker>
        ))
      }
    </MarkerClusterer>
  )
}

export default CustomMarkerClusterer;