// extracted by mini-css-extract-plugin
export var BtnOrange = "Map-module--BtnOrange--59814";
export var Container = "Map-module--Container--74576";
export var FlexWrap = "Map-module--FlexWrap--90d09";
export var H1 = "Map-module--H1--f3a33";
export var H2 = "Map-module--H2--c28e0";
export var H3 = "Map-module--H3--bf7a5";
export var H4 = "Map-module--H4--616ad";
export var H5 = "Map-module--H5--2d299";
export var H6 = "Map-module--H6--7b352";
export var Map = "Map-module--Map--c39b3";
export var MapContainer = "Map-module--MapContainer--41e68";
export var blockImg = "Map-module--block-img--c2113";
export var contentPage = "Map-module--contentPage--7c5fe";
export var hidden = "Map-module--hidden--09c40";
export var iconBox = "Map-module--iconBox--0ff58";
export var infoBox = "Map-module--infoBox--d4c65";
export var layerActive = "Map-module--layerActive--1103c";
export var loading = "Map-module--loading--099e0";
export var loadingSpinner = "Map-module--loadingSpinner--67a21";
export var mapContainer = "Map-module--mapContainer--5ec5d";
export var mapFilterTitle = "Map-module--mapFilterTitle--ee5ba";
export var sliderTray = "Map-module--sliderTray--9f627";
export var spinner = "Map-module--spinner--6c0c2";