import  React, {useState, useEffect, Fragment} from 'react'
import MarkerIcon from "./../images/marker-1.png"
import MarkerIconAlt from "./../images/marker-1-alt.png"
import MarkerIcon2 from "./../images/marker-2.svg"
import ChatMarker from "./../images/chat-marker.png"
import * as s from '../templates/WhereAmI.module.scss';
import { infoBox } from '../slices/Map.module.scss'
import {
  GoogleMap,
  InfoWindow,
  Polygon,
  Marker,
} from "@react-google-maps/api";
import { useMap } from '../utils/mapProvider';
import { PrismicLink } from '@prismicio/react'

const tableIcons = {
  'Cities': MarkerIcon,
  'Community': MarkerIconAlt,
  'School': MarkerIcon,
  'Chat': ChatMarker,
  'Business': MarkerIconAlt,
}

export const WhereAmIMap = ({lang, data}) => {
  
  const {
    coordinates, 
    status, 
    defaultLocation, 
    tables,
    isLoading,
    isInsidePolygon,
  } = useMap()

  const [map, setMap] = React.useState(null);

  const [clickCoord, setClickCoord] = useState(null);
  const [activePoly, setActivePoly] = useState([]);
  const [activeMarker, setActiveMarker] = useState(null);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActivePoly([]);
    setActiveMarker(marker);
  };
  const handleClickCoord = (ev) => {
    const coord = {lat: ev.latLng.lat(), lng:ev.latLng.lng() };
    const list = [];
    tables.map(x => {
      if(x.type === 'polygon') {
        x.data.map(y => {
          if(isInsidePolygon(coord, y.coordinates)){
            list.push(y)
          }
        })
      }
    })
    const map = new Map();
    list.forEach(item => {
      if(!map.has(item.type)){
        map.set(item.type, { type: item.type, data: [] });
        }
        map.get(item.type).data.push({ ...item });
    });

    const groupedByType = Array.from(map.values());

    setClickCoord(coord);
    setActivePoly(groupedByType);
    setActiveMarker(null);
  }
  

  const onMapLoad = React.useCallback(function callback(gmap) {
    setMap(gmap)
  }, []);

  return (
    <section className={s.WhereAmIContainer}>
      {!isLoading ? (
          
        <GoogleMap
          center={coordinates || defaultLocation}
          zoom={8}
          options={{ mapId: "68283565ee8dd767", disableDefaultUI: true, zoomControl: true, keyboardShortcuts: false }}
          mapContainerStyle={{ height: 484}}
          onLoad={onMapLoad}
          onClick={handleClickCoord}
        >
          <Marker
            position={coordinates}
            icon={MarkerIcon2}
            zIndex={4}
          />
       
          {!isLoading && tables?.map((table,i) => (
            <Fragment key={table.id}>
              {table.type === 'polygon' ? 
                table.data.map((x,j) => (
                  <Fragment key={`${x.name} - ${j}`}>
                    {x.coordinates && x.coordinates.length > 1 &&
                      <Polygon
                        paths={x.coordinates}
                        onClick={handleClickCoord}
                        options = {{fillColor: x.color, strokeWeight: 0.5}}
                      />
                    }
                  </Fragment>
                ))
              : 
                table.data.map((x, j) => (
                  <Marker 
                    position={x?.coordinates}
                    key={`${x?.name} - ${j}`}
                    onClick={() => handleActiveMarker(`${i}-${j}`)}
                    icon={tableIcons[x?.type] || tableIcons['Cities']}
                  >
                    {activeMarker === `${i}-${j}` && (
                      <InfoWindow 
                        onCloseClick={() => setActiveMarker(null)}
                      >
                      <div className={infoBox}>
                        <p><strong>{x?.name}</strong></p>
                        {x?.subtitle && 
                          <p style={{fontStyle: 'italic'}}>{x.subtitle}</p>
                        }
                        {x?.description && 
                          <p>{x?.description}</p>
                        }
                        {x?.descriptionHTML &&
                          <div className="inner" dangerouslySetInnerHTML={{__html: x.descriptionHTML}}/>
                        }
                        {x?.image && 
                          <img src={x.image} alt={''} style={{maxWidth: 200}}/>
                        }
                        {
                          x?.link &&
                          <PrismicLink href={x.link} style={{marginTop: 12}}>Learn More</PrismicLink>
                        }
                      </div>
                    </InfoWindow>
                    )}
                  </Marker>
                ))
              }
              {activePoly.length > 0 && (
                <InfoWindow position={clickCoord} onCloseClick={() => setActivePoly([])}>
                  <div className={infoBox} style={{gap: 12}}>
                    {activePoly?.map((x) => (
                      <Fragment key={x.type}>
                        <p><strong>{x.type}</strong></p>
                        <ul style={{marginLeft: 0, marginTop: 4, marginBottom: -4}}>
                          {x.data.map((y, i) => (
                            <li key={`${x.type} - ${i}`} style={{ marginBottom: 4}}>
                              <PrismicLink href={y.link} key={`${y.name} - ${i} - link`}>{y.name}</PrismicLink>
                            </li>
                          ))}
                          
                        </ul>
                      </Fragment>
                      
                    ))}
                  </div>
                </InfoWindow>
              )}
            </Fragment>
          ))}
        </GoogleMap>
      ) :
      status?.value === 1 ? (
        <div className={s.loading}>
          <p>Map Loading</p>
          <div className={s.loadingSpinner}>
          </div>
        </div>
      ) :
      <></>
    }
    </section>
  )
}
