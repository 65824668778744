// extracted by mini-css-extract-plugin
export var BtnOrange = "LanguageSwitcher-module--BtnOrange--03337";
export var Container = "LanguageSwitcher-module--Container--1864a";
export var FlexWrap = "LanguageSwitcher-module--FlexWrap--b1fa0";
export var H1 = "LanguageSwitcher-module--H1--1b51f";
export var H2 = "LanguageSwitcher-module--H2--eddb8";
export var H3 = "LanguageSwitcher-module--H3--42dff";
export var H4 = "LanguageSwitcher-module--H4--8d132";
export var H5 = "LanguageSwitcher-module--H5--3777d";
export var H6 = "LanguageSwitcher-module--H6--50012";
export var banner = "LanguageSwitcher-module--banner--e7102";
export var blockImg = "LanguageSwitcher-module--block-img--a8aeb";
export var contentPage = "LanguageSwitcher-module--contentPage--5923a";
export var language_switcher = "LanguageSwitcher-module--language_switcher--a5629";
export var sliderTray = "LanguageSwitcher-module--sliderTray--65d46";