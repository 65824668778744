import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import { TopMenu } from './TopMenu'
import { BottomMenu } from './BottomMenu'

export const Layout = ({ children, menu, activeDocMeta, title, description }) => {
  const queryData = useStaticQuery(graphql`
    query SiteQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: activeDocMeta.lang
        }}
      >
        <meta charSet="utf-8" />
        <title>{title ? title : queryData.site.siteMetadata.title}</title>
        <meta
          name="description"
          content={description ? description : queryData.site.siteMetadata.description}
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
          rel="stylesheet"
        />
      </Helmet>
      <div style={{display: "flex"}}>
        <TopMenu menu={menu} activeDocMeta={activeDocMeta} />
        <div style={{flexGrow: 1, flexShrink: 1}}>
          <main id="main">{children}</main>
          <BottomMenu menu={menu} activeDocMeta={activeDocMeta} />
        </div>
      </div>
    </>
  )
}
