import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import {iconStyle} from './../../slices/Map.module.scss'
import {icon as IconStyle} from './../../templates/WhereAmI.module.scss'
export const Icon = ({ icon}) => {
  return (
    <div className={IconStyle}>
      <GatsbyImage
        image={icon?.gatsbyImageData}
        objectFit="contain"
        alt=""
        className={iconStyle}
      />
    </div>
  )
}

