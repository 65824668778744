import React from "react"
import CustomMarkerClusterer from "./CustomMarkerCluster"
import CustomPolygons from "./CustomPolygons"
import { useMap } from "../../utils/mapProvider"

export const LayerComponent = ({ layer }) => {
  switch (layer.tableId) {
    case 'schools':
      return <SchoolsLayer  />
    case 'cities':
      return <CitiesLayer  />
    case 'treaties':
      return <TreatiesLayer  />
    case 'territories':
      return <TerritoriesLayer  />
    case 'communities':
      return <CommunitiesLayer  />
    case 'businesses':
      return <BusinessesLayer  />
    case 'chats':
      return <ChatsLayer  />
    default:
      return null;
  }
}

const SchoolsLayer = () => {
  const {schools: layer} = useMap();
  return <CustomMarkerClusterer data={layer} isActive={true} />
}

const CitiesLayer = () => {
  const {cities: layer} = useMap();
  return <CustomMarkerClusterer data={layer} isActive={true} />
}

const TreatiesLayer = () => {
  const {treaties: layer} = useMap();
  return <CustomPolygons data={layer} isActive={true} />
}

const TerritoriesLayer = () => {
  const {territories: layer} = useMap();
  return <CustomPolygons data={layer} isActive={true} />
}

const CommunitiesLayer = () => {
  const {communities: layer} = useMap();
  return <CustomMarkerClusterer data={layer} isActive={true} />
}

const BusinessesLayer = () => {
  const {businesses: layer} = useMap();
  return <CustomMarkerClusterer data={layer} isActive={true} />
}

const ChatsLayer = () => {
  const {chats: layer} = useMap();
  return <CustomMarkerClusterer data={layer} isActive={true} />
}